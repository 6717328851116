<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="customerModal"
    >   
        <CForm id="customer_form">
          <CRow>
              <CCol md="12">
                <CInput
                    type="file"
                    name="cif_file"
                    id="cif_file"
                    label="Cédula de Idenficación Fiscal - CIF (opcional):"
                    :lazy="false"
                    :value.sync="$v.form.cif_file.$model"
                    placeholder=""
                    autocomplete="cif_file"
                    @change="parseCIF()"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol :md="form.rfc == 'XEXX010101000' ? 3 : 4">
                <CInput
                  label="RFC:"
                  :lazy="false"
                  :value.sync="$v.form.rfc.$model"
                  :isValid="checkIfValid('rfc')"
                  placeholder=""
                  autocomplete="rfc"
                  maxLength="13"
                  invalidFeedback="Este campo debe estar conformado 12 o 13 caracteres."
              />
              </CCol>
              <CCol :md="form.rfc == 'XEXX010101000' ? 6 : 8">
                <CInput
                  label="Razón Social:"
                  :lazy="false"
                  :value.sync="$v.form.fiscal_name.$model"
                  :isValid="checkIfValid('fiscal_name')"
                  placeholder=""
                  autocomplete="fiscal-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
              />
              </CCol>
              <CCol md="3" v-show="this.form.rfc == 'XEXX010101000'">
                <CInput
                    label="Reg. Id:"
                    :lazy="false"
                    :value.sync="$v.form.identifier.$model"
                    placeholder=""
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="8">
                <CInput
                  label="Domicilio:"
                  :lazy="false"
                  :value.sync="$v.form.street.$model"
                  placeholder=""
                  autocomplete="street"
              />
              </CCol>
              <CCol md="2">
                <CInput
                  label="No. Exterior:"
                  :lazy="false"
                  :value.sync="$v.form.exterior.$model"
                  placeholder=""
                  autocomplete="exterior"
              />
              </CCol>
              <CCol md="2">
                <CInput
                  label="No. Interior:"
                  :lazy="false"
                  :value.sync="$v.form.interior.$model"
                  placeholder=""
                  autocomplete="interior"
              />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="4">
                <CInput
                  label="Colonia:"
                  :lazy="false"
                  :value.sync="$v.form.colony.$model"
                  placeholder=""
                  autocomplete="colony"
                />
              </CCol>
              <CCol md="4">
                <CInput
                  label="Ciudad:"
                  :lazy="false"
                  :value.sync="$v.form.municipality.$model"
                  placeholder=""
                  autocomplete="municipality"
                />
              </CCol>
              <CCol md="4">
                <CInput
                  label="C.P.:"
                  :lazy="false"
                  :value.sync="$v.form.zipcode.$model"
                  :isValid="checkIfValid('zipcode')"
                  placeholder=""
                  autocomplete="zipcode"
                  maxLength="5"
                  invalidFeedback="Este campo debe estar conformado 5 dígitos numéricos."
              />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="6" v-show="this.form.rfc != 'XEXX010101000'">
                <CSelect
                  label="Estado: "
                  :value.sync="$v.form.state.$model"
                  :isValid="checkIfValid('state')"
                  :options="statesOptions"
                />
              </CCol>
              <CCol :md="this.form.rfc == 'XEXX010101000' ? 12 : 6">
                <CSelect
                  label="País: "
                  :value.sync="$v.form.country.$model"
                  :isValid="checkIfValid('country')"
                  :options="this.form.rfc != 'XEXX010101000' ? countriesOptions : countriesWithoutMexicoOptions"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="6">
                <CInput
                  label="Correo Electrónico: "
                  type="email"
                  :lazy="false"
                  :value.sync="$v.form.email.$model"
                  :isValid="checkIfValid('email')"
                  placeholder=""
                  autocomplete="email"
                  invalidFeedback="Este campo debe ser un correo electrónico válido."
                />
              </CCol>
              <CCol md="3">
                <CInput
                  label="Teléfono: "
                  :lazy="false"
                  :value.sync="$v.form.phone.$model"
                  :isValid="checkIfValid('phone')"
                  placeholder=""
                  autocomplete="phone"
                  maxLength="10"
                  invalidFeedback="Este campo debe estar conformado 10 dígitos numéricos."
                />
              </CCol>
              <CCol md="3">
                <CInput
                  label="Celular: "
                  :lazy="false"
                  :value.sync="$v.form.mobile.$model"
                  :isValid="checkIfValid('mobile')"
                  placeholder=""
                  autocomplete="mobile"
                  maxLength="10"
                  invalidFeedback="Este campo debe estar conformado 10 dígitos numéricos."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CSelect
                  label="Régimen Fiscal: "
                  :isValid="checkIfValid('regimen_code')"
                  :value.sync="$v.form.regimen_code.$model"
                  :options="regimensOptions"
                  @change="changeRegimen"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CSelect
                  label="Uso de CFDI: "
                  :isValid="checkIfValid('uso_cfdi_code')"
                  :value.sync="$v.form.uso_cfdi_code.$model"
                  :options="usosOptions"
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"
import general_ws from '../../services/general';
import ws from '../../services/customers';
import store from '../../store'

export default {
  name: 'CustomerModal',
  props: {

  },
  mounted: async function() {
    let response3 = await general_ws.getRegimens();

    if(response3.type == "success"){
      this.regimens = response3.data;
    }

    let response2 = await general_ws.getCfdiUsosByRegimen("601");

    if(response2.type == "success"){
      this.usos_cfdi = response2.data;
      this.usosOptions = await this.$parseSelectOptionsOrdered(this.usos_cfdi, "code", "code:name");
    }

    let response4 = await general_ws.getStates(); 

    if(response4.type == "success"){
      this.states = response4.data;
    }

    let response5 = await general_ws.getCountries(); 

    if(response5.type == "success"){
      this.countries = response5.data;
    }

    this.usosOptions = await this.$parseSelectOptionsOrdered(this.usos_cfdi, "code", "code:name");
    this.regimensOptions = await this.$parseSelectOptionsOrdered(this.regimens, "code", "code:name");
    this.statesOptions = await this.$parseSelectOptionsOrdered(this.states, "code", "name",[{"label": "Sin Estado Asignado","value":""}]);
    this.countriesOptions = await this.$parseSelectOptionsOrdered(this.countries, "code", "name");
    this.countriesWithoutMexicoOptions = await this.$discardOptions(this.countriesOptions, [{code : "MEX"}], "value:code");
  },
  data () {
    return {
      customerModal: false,
      title: "Nuevo Cliente",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      usos_cfdi: [],
      usosOptions: [],
      regimens: [],
      regimensOptions: [],
      states: [],
      statesOptions: [],
      countries: [],
      countriesOptions: [],
      countriesWithoutMexicoOptions: [],
    }
  },
  methods: {
    async parseCIF () {
      const form = document.getElementById("customer_form");
      const formData = new FormData(form);

      this.loading();
      let response = await ws.parseCIF(formData);

      if(response.type == "success"){
        this.form.rfc = response.data.rfc;
        this.form.fiscal_name = response.data.name;
        this.form.zipcode = response.data.zipcode;
        this.form.regimen_code = response.data.regimen_code;

        let response2 = await general_ws.getCfdiUsosByRegimen(this.form.regimen_code);

        if(response2.type == "success"){
          this.usos_cfdi = response2.data;
          this.usosOptions = await this.$parseSelectOptionsOrdered(this.usos_cfdi, "code", "code:name");
        }

        this.form.street = response.data.street;
        this.form.exterior = response.data.exterior;
        this.form.interior = response.data.interior;
        this.form.state = response.data.state_code;
      }
      else{
        this.showToast(response.type, response.message);
      }

      this.loaded();
    },
    storeModal () {
      this.customerModal = true;
      this.color = "info";
      this.title = 'Nuevo Cliente';
      this.isEdit = false;
      this.submitted = false;

      this.form = this.getEmptyForm();
    },
    async updateModal (item) {
      this.customerModal = true;
      this.color = "warning";
      this.title = 'Editar Cliente';
      this.isEdit = true;
      this.submitted = false;

      let response = await general_ws.getCfdiUsosByRegimen(item.regimen);

      if(response.type == "success"){
        this.usos_cfdi = response.data;
        this.usosOptions = await this.$parseSelectOptionsOrdered(this.usos_cfdi, "code", "code:name");
      }

      this.form = {
          id: item.id,
          rfc: item.rfc,
          identifier: item.identifier,
          fiscal_name: item.name,
          street: item.street,
          exterior: item.exterior,
          interior: item.interior,
          colony: item.colony,
          municipality: item.municipality,
          zipcode: item.zipcode,
          state: item.state,
          country: item.country,
          name: item.name,
          email: item.email,
          phone: item.phone,
          mobile: item.mobile,
          uso_cfdi_code: item.uso_cfdi,
          uso_cfdi_name: "Gastos en General",
          regimen_code: item.regimen,
          regimen_name: "GENERAL DE LEY PERSONAS MORALES",
          cif_file: ''
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    async changeRegimen () {
      let regimen = await this.$findElementByCode(this.regimens, this.form.regimen_code);

      //Consultar los usos de CFDI para el regimen
      let response = await general_ws.getCfdiUsosByRegimen(regimen.code);

      if(response.type == "success"){
        this.usos_cfdi = response.data;
        this.usosOptions = await this.$parseSelectOptionsOrdered(this.usos_cfdi, "code", "code:name");
      }

      let uso_cfdi = await this.$findElementByCode(this.usos_cfdi, this.form.uso_cfdi_code);

      if(uso_cfdi === undefined){
        uso_cfdi = this.usos_cfdi[0];
      }

      this.form.regimen_code = regimen.code;
      this.form.regimen_name = regimen.name;
    },
    closeModal () {
      this.customerModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          rfc: '',
          identifier: '',
          fiscal_name: '',
          street: '',
          exterior: '',
          interior: '',
          colony: '',
          municipality: '',
          zipcode: '',
          state: 'JAL',
          country: 'MEX',
          email: '',
          phone: '',
          mobile: '',
          uso_cfdi_code: "G03",
          uso_cfdi_name: "Gastos en General",
          regimen_code: "601",
          regimen_name: "GENERAL DE LEY PERSONAS MORALES",
          cif_file: ''
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      rfc: {
        required,
        minLength: minLength(12),
        maxLength: maxLength(13)
      },
      identifier: false,
      fiscal_name: {
        required,
        minLength: minLength(3)
      },
      street: {},
      exterior: {},
      interior: {},
      colony: {},
      municipality: {},
      zipcode: {
        required,
        integer,
        minLength: minLength(5),
        maxLength: maxLength(5)
      },
      state: {},
      country: {},
      email: {
        email
      },
      phone: {
        integer,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      mobile: {
        integer,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      uso_cfdi_code: {
        required
      },
      regimen_code: {
        required
      },
      cif_file: false
    }
  },
}
</script>